import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const Sign = Loadable(lazy(() => import('views/pages/authentication/authentication3/Sign')));
const Credible = Loadable(lazy(() => import('views/pages/authentication/authentication3/Credible')));
const Verify = Loadable(lazy(() => import('views/pages/authentication/authentication3/Verify')));
const URLVerify = Loadable(lazy(() => import('views/pages/authentication/authentication3/UrlVerify')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/pages/login/login3',
            element: <AuthLogin3 />
        },
        {
            path: '/pages/sign',
            element: <Sign />
        },
        {
            path: '/pages/verify',
            element: <Verify />
        },
        {
            path: '/pages/credible',
            element: <Credible />
        },
        {
            path: '/pages/register/register3',
            element: <AuthRegister3 />
        },
        {
            path: '/:id',
            element: <URLVerify />
        }
    ]
};

export default AuthenticationRoutes;
